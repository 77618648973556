<template>
  <div class="personalInfo">
    <Header title="设置" />
    <div class="main">
      <div @click="changeAvatar" class="avatarBox">
        <ImgDecypt class="userAvatar" round :src="userInfo.portrait" :key="userInfo.portrait" />
      </div>
      <div class="changeBtn" @click="changeAvatar">
        <svg-icon class="changeIcon" iconClass="changeIcon" />
        切换
      </div>
      <router-link tag="div" to="/actRecovery" class="cellItem">
        <div class="cellTitle">账号找回</div>
        <svg-icon class="cellRigthArrow" iconClass="cellRightArrow" />
      </router-link>
      <div class="cellItem" @click="jumpPhone">
        <div class="cellTitle">绑定手机号</div>
        <div class="cellContent" v-if="userInfo.mobile">{{ userInfo.mobile }}</div>
        <svg-icon class="cellRigthArrow" iconClass="cellRightArrow" />
      </div>
      <div class="cellItem" @click="bindInviter">
        <div class="cellTitle">绑定邀请码</div>
        <svg-icon class="cellRigthArrow" iconClass="cellRightArrow" v-if="!userInfo.hasBindInviterCode" />
        <div class="cellRigthArrow" v-else>已绑定</div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgDecypt from '@/components/ImgDecypt';
import Header from '@/components/Header';
import { modifyPersonInfo } from '@/api/user';
import { Popup, Toast, RadioGroup, Radio } from 'vant';

export default {
  name: 'PersonalInfo',
  components: {
    Header,
    ImgDecypt,
    [Popup.name]: Popup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  computed: {
    gender() {
      if (this.userInfo.gender == '') {
        return '';
      }
      if (this.userInfo.gender == 'man') {
        return '男';
      }
      if (this.userInfo.gender == 'woman') {
        return '女';
      }
      return '';
    },
  },
  data() {
    return {
      userSex: '',
      isChangeSex: false,
      userInfo: {},
      currentSex: undefined,
      activeIcon: require('@/assets/png/activeIcon.png'),
      inactiveIcon: require('@/assets/png/inactiveIcon.png'),
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    // 获取用户信息
    async getUserInfo() {
      await this.$store.dispatch('user/getUserInfo');
      this.userInfo = this.$store.getters.userInfo;
      this.currentSex = this.userInfo.gender;
    },
    // 跳转修改昵称
    jumpNickName() {
      if (this.userInfo.isVip && this.userInfo.vipLevel > 0) {
        this.$router.push({
          path: '/nickName',
          query: { name: this.userInfo.name },
        });
      } else {
        // Toast('抱歉，只有会员才可以修改资料')
        this.$bus.$emit('promptPopupEvent', '您还不是VIP无法修改昵称');
      }
    },
    // 跳转修改简介
    jumpIntroduction() {
      // this.$router.push({path: '/introduction', query:{introduce: this.userInfo.introduce}})
      if (this.userInfo.isVip && this.userInfo.vipLevel > 0) {
        this.$router.push({
          path: '/introduction',
          query: { introduce: this.userInfo.introduce },
        });
      } else {
        // Toast('抱歉，只有会员才可以修改资料')
        this.$bus.$emit('promptPopupEvent', '您还不是VIP无法修改简介');
      }
    },
    // 修改所在地
    jumoCity() {
      this.$router.push({ path: '/city', query: { cityType: 'user' } });
      // if (this.checkVip()) {
      //     this.$router.push({path: '/city', query:{cityType: "user"}})
      // } else {
      //     Toast('抱歉，只有会员才可以修改资料')
      // }
    },
    // 修改性别
    changeSex() {
      this.isChangeSex = true;
      // if (this.checkVip()) {
      //     this.isChangeSex = true
      // } else {
      //     Toast('抱歉，只有会员才可以修改资料')
      // }
    },
    // 选择性别
    async selectSex() {
      let req = { gender: this.currentSex };
      try {
        let res = await this.$Api(modifyPersonInfo, req);
        this.isChangeSex = false;
        if (res && res.code == 200) {
          Toast('修改成功');
          this.getUserInfo();
        } else {
          Toast(res.tip || '修改失败，请稍后再试');
        }
      } catch (error) {
        this.isChangeSex = false;
        Toast('操作失败');
      }
    },
    // 校验是否会员、会员时间是否到期
    checkVip() {
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(this.userInfo.vipExpireDate).getTime();
      if (this.userInfo && this.userInfo.isVip && todayTime < effectiveTime) {
        return true;
      }
      return false;
    },
    // 手机号跳转
    jumpPhone() {
      if (this.userInfo.mobile) {
        this.$router.push({
          path: '/phonePage',
          query: { phone: this.userInfo.mobile },
        });
      } else {
        this.$router.push('/bindPhone');
      }
    },
    changeAvatar() {
      this.$router.push('/changeAvatar');
      // if (this.checkVip()) {
      //     this.$router.push("/changeAvatar")
      // } else {
      //     Toast('抱歉，只有会员才可以切换头像')
      // }
    },
    bindInviter() {
      if (this.userInfo.hasBindInviterCode) {
        Toast('您已绑定了邀请码');
      } else {
        this.$router.push('/bindInvitationCode');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.personalInfo {
  height: 100%;
  background-color: #fff;

  .navBar {
    height: 44px;
    display: flex;
    padding: 0 16px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }

      span {
        font-size: 18px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 25px;
    box-sizing: border-box;
    .avatarBox {
      width: 73px;
      height: 73px;
      border-radius: 50%;
      background: #f0f0f0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 21px auto 0;
    }
    .userAvatar {
      width: 61px;
      height: 61px;
      // margin: 18px auto 0px;
    }

    .changeBtn {
      height: 31px;
      width: 76px;
      font-size: 13px;
      border-radius: 10px;
      margin: 14.7px auto 38px;
      color: #fff;
      background-image: linear-gradient(to right, #f74a82, #fca984);
      display: flex;
      align-items: center;
      justify-content: center;

      .changeIcon {
        height: 15.7px;
        width: 14.5px;
        margin-right: 9.3px;
      }
    }

    .cellType {
      font-size: 18px;
      margin: 30px 0 13px;
    }

    .cellItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 21px;
      padding-bottom: 23px;
      border-bottom: 2px solid rgba(121, 121, 121, 0.2);

      .cellTitle {
        font-size: 14px;
        color: #8a8a8a;
      }
      .cellContent {
        flex: 2;
        margin: 0 8px;
        color: #8a8a8a;
        text-align: right;
      }

      .content {
        font-size: 17px;
        color: rgb(111, 93, 128);

        .pink {
          color: rgb(255, 103, 143);
        }
      }

      .personalArrow {
        margin-left: 3px;
        height: 20px;
        width: 20px;
      }
    }
  }

  .sexSelect {
    color: #000;
    text-align: center;
    font-size: 18px;
    border-radius: 12px 12px 0 0;

    .title {
      font-size: 20px;
      text-align: center;
      height: 48px;
      line-height: 48px;
      border-bottom: 1px solid rgb(229, 237, 245);
      margin: 0 12px;
    }

    .sexBox {
      margin: 28px auto;
      width: 170px;
      display: flex;
      font-size: 20px;
      justify-content: space-between;
      .maleBox {
        display: flex;
      }
      .femaleBox {
        display: flex;
      }
      .pink {
        color: rgb(255, 90, 144);
      }
      .img-icon {
        height: 20px;
        margin-left: 10px;
      }
    }

    .confirm {
      height: 38px;
      width: 190px;
      margin: 0 auto 36px;
      background-color: rgb(255, 103, 143);
      text-align: center;
      border-radius: 4px;
      color: #fff;
      line-height: 38px;
    }

    .sexItem {
      height: 65px;
      line-height: 65px;
      border-bottom: 1px solid #d9d9d9;
    }

    .sexItem:active {
      color: #fff;
      background: #fe7f0f;
    }

    .sexItem:last-child {
      border: none;
    }
  }

  .mt37 {
    margin-top: 37px;
  }

  .mb40 {
    margin-bottom: 40px;
  }
}
</style>
